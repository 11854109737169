@import url(https://fonts.googleapis.com/css?family=Poppins);
/* VARIABLES */
:root {
  --verde_claro: #c1e05f;
  --verde_oscuro: #95c11f;
  --verde_radio_btn: #3FBF3F;
  --negro: #313131;
  --gris_texto: #707070;
  --gris_oscuro: #c4c4c4;
  --gris_claro: #e8e9eb;
  --blanco: #fff;
  --sombra: #D6D6D6;
  --texto_boton: #777777;
  --azul: #009fe3;
  --rojo: #e20e18;
  --rojo_oscuro: #AC1A1F;
  --fuente: "Poppins";
  --form_size: 18px;
}

body{
  overflow-x: hidden !important;
  overflow-y: overlay;
}

.App {
  background-image: url(/static/media/trama.4710c599.png);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: #fff;
  background: var(--blanco); 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #D6D6D6;
  background: var(--sombra);
}


/*** GENERAL ***/

.between_line {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  font-size: 50px;
}

.between_line:not(:empty)::before {
  margin-right: 2em;
}

.between_line:not(:empty)::after {
  margin-left: 2em;
}

.between_line_white::before, .between_line_white::after {
  content: '';
  -webkit-flex: 1 1;
          flex: 1 1;
  border-bottom: 1px solid #fff;
  border-bottom: 1px solid var(--blanco);
  margin-right: 15%;
  margin-left: 15%;
}

.between_line_red::before, .between_line_red::after {
  content: '';
  -webkit-flex: 1 1;
          flex: 1 1;
  border-bottom: 1px solid #AC1A1F;
  border-bottom: 1px solid var(--rojo_oscuro);
  margin-right: 15%;
  margin-left: 15%;
}

.between_line_black::before, .between_line_black::after {
  content: '';
  -webkit-flex: 1 1;
          flex: 1 1;
  border-bottom: 1px solid #313131;
  border-bottom: 1px solid var(--negro);
  margin-right: 15%;
  margin-left: 15%;
}


@media (max-width: 414px) {
  .between_line_white::before, .between_line_white::after {
    margin-right: 10px;
    margin-left: 10px;
  }
  
  .between_line_red::before, .between_line_red::after {
    margin-right: 10px;
    margin-left: 10px;
  }
  
  .between_line_black::before, .between_line_black::after {
    margin-right: 10px;
    margin-left: 10px;
  }
}

/*** NAV ***/

#mainNav {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.nav_dark {
  background-color: #313131;
  background-color: var(--negro);
}

#mainNav .logo{
  height: 2.5rem;
}

.nav_dark .text1{
  font-family: "Poppins";
  font-family: var(--fuente);
  color: #c4c4c4;
  color: var(--gris_oscuro);
  font-size: 22px;
  font-weight: lighter;
}

.nav_dark .text1 span{
  color: #c1e05f;
  color: var(--verde_claro);
  font-size: 13px;
}

.nav_dark .text1 .separater{
  color: #fff;
  color: var(--blanco);
  font-size: 15px;
  margin-left: 10px;
  margin-right: 10px;
}

.nav_transparent{
  color: transparent;
}

#mainNav .logo{
  height: 3rem;
}

@media (max-width: 1400px) {
  #mainNav .logo{
    height: 4.5rem;
  }
  .nav_dark {
    padding-top: 1em !important;
    padding-bottom: 1rem !important;
  }
  .nav_dark .text1{
    margin-right: 2rem;
  }
  .nav_dark .text1 span{
    color: #c1e05f;
    color: var(--verde_claro);
  }
}

@media (max-width: 1200px) {
  #mainNav .logo{
    height: 4rem;
  }
  .nav_dark .text1{
    font-size: 23px;
  }
  .nav_dark .text1 span{
    font-size: 13px;
  }
  .nav_dark .text1 .separater{
    font-size: 13px;
    margin-left: 7px;
    margin-right: 7px;
  }
}

@media (max-width: 992px) {
  .nav_dark .text1{
    font-size: 21px;
  }
  .nav_dark .text1 span{
    font-size: 12px;
  }
  .nav_dark .text1 .separater{
    font-size: 12px;
    margin-left: 5px;
    margin-right: 5px;
  }
}

@media (max-width: 767px) {
  #mainNav .logo{
    height: 4rem;
  }
  .nav_dark .text1{
    font-size: 22px;
  }
  .nav_dark .text1 span{
    font-size: 12px;
  }
  .nav_dark .text1 .separater{
    font-size: 10px;
    margin-left: 5px;
    margin-right: 5px;
  }
}

@media (max-width: 575px) {
  .nav_dark .text1{
    font-size: 20px;
  }
  .nav_dark .text1 span{
    font-size: 10px;
  }
  .nav_dark .text1 .separater{
    font-size: 10px;
    margin-left: 5px;
    margin-right: 5px;
  }
}

@media (max-width: 426px) {
  #mainNav .logo{
    height: 2.5rem !important;
  }
  .nav_dark .text1{
    font-size: 20px;
  }
  .nav_dark .text1 span{
    font-size: 10px;
  }
  .nav_dark .text1 .separater{
    font-size: 10px;
    margin-left: 4px;
    margin-right: 4px;
  }
}

@media (max-width: 374px) {
  #mainNav .logo{
    height: 2.2rem !important;
  }
  .nav_dark .text1{
    font-size: 18px;
  }
  .nav_dark .text1 span{
    font-size: 9.5px;
  }
  .nav_dark .text1 .separater{
    font-size: 8px;
    margin-left: 4px;
    margin-right: 4px;
  }
}

@media (max-width: 339px) {
  #mainNav .logo{
    height: 2rem !important;
  }
  .nav_dark .text1{
    font-size: 15px;
  }
  .nav_dark .text1 span{
    font-size: 8.5px;
  }
  .nav_dark .text1 .separater{
    font-size: 8px;
    margin-left: 4px;
    margin-right: 4px;
  }
}


/*** HEADER ***/

.header .masthead {
  height: 100vh;
  background-image: url(/static/media/trama_gris.eafd450a.png);
  background-color: #1a1a19;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
}

.header .header_title{
  font-size: 4rem;
  font-weight: 900;
  line-height: 3.6rem;
  font-family: "Poppins";
  font-family: var(--fuente);
}

.header .header_subtitle{
  font-size: 2.2rem;
  line-height: 2.2rem;
  font-family: "Poppins";
  font-family: var(--fuente);
}

@media (max-width: 1400px) {
  .header .header_title{
    font-size: 3rem;
  }
  .header .header_subtitle{
    font-size: 2rem;
  }
}

@media (max-width: 414px) {
  .header .header_title{
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
  .header .header_subtitle{
    font-size: 1.6rem;
    line-height: 1.6rem;
  }
}

@media (max-width: 390px) {
  .header .header_title{
    font-size: 2.3rem;
    line-height: 2.5rem;
  }
  .header .header_subtitle{
    font-size: 1.5rem;
    line-height: 1.6rem;
  }
}

.header .text_red{
  color: #AC1A1F;
  color: var(--rojo_oscuro);
}


/*** ABOUT ***/

.about {
  background-color: rgba(122, 92, 92, 0.4);
}

.about a {
  color: #707070;
  color: var(--gris_texto);
}

.about .title{
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 35px;
  color: #313131;
  color: var(--negro);
}

.about .subtitle{
  font-family: "Poppins";
  font-family: var(--fuente);
  font-size: 21px;
  color: #707070;
  color: var(--gris_texto);
}

.about .arrow_down {
  border-style: solid;
  border-width: 40px;
  width: 0;
  height: 0;
  margin-left: 45%;
}

@media(max-width: 1024px) {
  .about .arrow_down {
    margin-left: 42%;
  } 
}

@media(max-width: 768px) {
  .about .arrow_down {
    margin-left: 45%;
  } 
}

@media(max-width: 500px) {
  .about .arrow_down {
    margin-left: 38%;
  } 
}

.about .arrow_down > div {
  border-style: solid;
  border-width: 25px;
  width: 0;
  height: 0;
  position: relative;
  margin-top: -24px;
  margin-left: -14px;
}

.about .arrow_down{
  border-color: #707070 transparent transparent transparent;
}

.about .arrow_down > div {
  border-color: #fff transparent transparent transparent;
  top:-21px;
  left:-10px;
}

.about .logo {
  width: 400px;
  height: auto;
}

@media(max-width: 1024px) {
  .about .logo {
    width: 330px;
  } 
}

@media(max-width: 768px) {
  .about .logo {
    width: 300px;
  } 
}

@media(max-width: 414px) {
  .about .logo {
    width: 250px;
  } 
}

.about .img_etm {
  width: 300px;
  height: auto;
  margin-top: 15px;
}

.about .img_corfo {
  width: 250px;
  height: auto;
}

@media(max-width: 768px) {
  .about .img_etm {
    width: 250px;
  }
  
  .about .img_corfo {
    width: 200px;
  }
}

@media(max-width: 414px) {
  .about .img_etm {
    width: 180px;
  }
  
  .about .img_corfo {
    width: 160px;
  }
}


/*** benefits ***/

.benefits .title{
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 35px;
  color: #313131;
  color: var(--negro);
}

.benefits .title{
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 35px;
  color: #313131;
  color: var(--negro);
}

.benefits .subtitle{
  font-family: "Poppins";
  font-family: var(--fuente);
  font-size: 20px;
  color: #707070;
  color: var(--gris_texto);
}

.benefits .text_red{
  color: #AC1A1F;
  color: var(--rojo_oscuro);
}


/*** TIMELINE ***/

.timeline .title{
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 35px;
  color: #313131;
  color: var(--negro);
}

.timeline .timeline_titles {
  font-size: 20px;
  margin-left: 80px;
  margin-right: 60px;
}

.timeline .timeline_titles_mobile {
  display: none;
}

@media(max-width: 1024px){
  .timeline .timeline_titles {
    font-size: 18px;
    margin-left: 90px;
    margin-right: 140px;
  }
}

@media(max-width: 768px){
  .timeline .timeline_titles {
    font-size: 16px;
    margin-left: 80px;
    margin-right: 80px;
  }
  
  .timeline .timeline_titles h6 {
    font-size: 14px;
  }
}

@media(max-width: 768px){
  .timeline .timeline_titles {
    display: none;
  }
  
  .timeline .timeline_titles_mobile {
    display: list-item;
  }
}

/* .timeline .etapa_b_0 {
  margin-left: -63%;
}

.timeline .etapa_b_1 {
  margin-left: -31%;
}

.timeline .etapa_b_2 {
  margin-left: 0%;
}

.timeline .etapa_b_3 {
  margin-left: 32%;
}

.timeline .etapa_b_4 {
  margin-left: 64%;
}

.timeline .etapa_t_0 {
  margin-left: -63%;
  margin-bottom: -30px;
}

.timeline .etapa_t_1 {
  margin-left: -31%;
}

.timeline .etapa_t_2 {
  margin-left: 0%;
}

.timeline .etapa_t_3 {
  margin-left: 32%;
}

.timeline .etapa_t_4 {
  margin-left: 64%;
}

.timeline .etapa_b_actual {
  display: none;
}

@media(max-width: 1024px){
  .timeline .etapa_b_0 {
    margin-left: -65%;
  }
  
  .timeline .etapa_b_1 {
    margin-left: -35%;
  }
  
  .timeline .etapa_b_2 {
    margin-left: -4%;
  }
  
  .timeline .etapa_b_3 {
    margin-left: 26%;
  }
  
  .timeline .etapa_b_4 {
    margin-left: 50%;
  }
  
  .timeline .etapa_t_0 {
    margin-left: -65%;
  }
  
  .timeline .etapa_t_1 {
    margin-left: -35%;
  }
  
  .timeline .etapa_t_2 {
    margin-left: -4%;
  }
  
  .timeline .etapa_v_3 {
    margin-left: 26%;
  }
  
  .timeline .etapa_t_4 {
    margin-left: 50%;
  }

  .timeline .etapa_b_actual {
    display: none;
  }
}

@media(max-width: 768px){
  .timeline .etapa_b_0 {
    margin-left: -56%;
  }
  
  .timeline .etapa_b_1 {
    margin-left: -28%;
  }
  
  .timeline .etapa_b_2 {
    margin-left: -1%;
  }
  
  .timeline .etapa_b_3 {
    margin-left: 26%;
  }
  
  .timeline .etapa_b_4 {
    margin-left: 58%;
  }
  
  .timeline .etapa_t_0 {
    margin-left: -56%;
  }
  
  .timeline .etapa_t_1 {
    margin-left: -28%;
  }
  
  .timeline .etapa_t_2 {
    margin-left: -1%;
  }
  
  .timeline .etapa_t_3 {
    margin-left: 26%;
  }
  
  .timeline .etapa_t_4 {
    margin-left: 58%;
  }

  .timeline .etapa_b_actual {
    display: none;
  }
}

@media(max-width: 600px){
  .timeline .etapa_b_0 {
    display: none;
  }
  
  .timeline .etapa_b_1 {
    display: none;
  }
  
  .timeline .etapa_b_2 {
    display: none;
  }
  
  .timeline .etapa_b_3 {
    display: none;
  }
  
  .timeline .etapa_b_4 {
    display: none;
  }
  
  .timeline .etapa_t_0 {
    display: none;
  }
  
  .timeline .etapa_t_1 {
    display: none;
  }
  
  .timeline .etapa_t_2 {
    display: none;
  }
  
  .timeline .etapa_t_3 {
    display: none;
  }
  
  .timeline .etapa_t_4 {
    display: none;
  }
  
  .timeline .etapa_b_actual {
    display: list-item;
    margin-left: 0%;
  }
} */

.timeline .text_red{
  color: #AC1A1F;
  color: var(--rojo_oscuro);
  font-weight: bold;
}

.timeline .bg_red {
  background-color: #AC1A1F;
  background-color: var(--rojo_oscuro);
  color: #fff;
  color: var(--blanco);
}



/*** ABOUT ***/

.announcement {
  background-color: rgba(122, 92, 92, 0.4);
}

.announcement a {
  color: #313131;
  color: var(--negro);
}

.announcement a:hover {
  color: #AC1A1F;
  color: var(--rojo_oscuro);
}

.announcement .icon {
  color: #AC1A1F;
  color: var(--rojo_oscuro);
}

.announcement .title{
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 35px;
  color: #313131;
  color: var(--negro);
}

.announcement .subtitle{
  font-family: "Poppins";
  font-family: var(--fuente);
  font-size: 21px;
  color: #313131;
  color: var(--negro);
}

.announcement .arrow_down {
  border-style: solid;
  border-width: 40px;
  width: 0;
  height: 0;
  margin-left: 45%;
}

@media(max-width: 1024px) {
  .announcement .arrow_down {
    margin-left: 42%;
  } 
}

@media(max-width: 768px) {
  .announcement .arrow_down {
    margin-left: 45%;
  } 
}

@media(max-width: 500px) {
  .announcement .arrow_down {
    margin-left: 38%;
  } 
}

.announcement .arrow_down > div {
  border-style: solid;
  border-width: 25px;
  width: 0;
  height: 0;
  position: relative;
  margin-top: -24px;
  margin-left: -14px;
}

.announcement .arrow_down{
  border-color: #707070 transparent transparent transparent;
}

.announcement .arrow_down > div {
  border-color: #fff transparent transparent transparent;
  top:-21px;
  left:-10px;
}


/*** STARTUPS ***/

.startups .title{
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 35px;
  color: #313131;
  color: var(--negro);
}

.startups .logo{
  height: auto;
  max-height: 8rem;
  /* object-fit: cover; */
}

.startups .logo_title{
  font-size: 21px;
  color: #313131;
  color: var(--negro);
  font-weight: 600;
}

.startups .logo_subtitle{
  font-size: 21px;
  color: #313131;
  color: var(--negro);
}


/*** SPEAKERS ***/

.speakers .title{
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 35px;
  color: #313131;
  color: var(--negro);
}

.speakers .logo{
  height: auto;
  max-height: 15rem;
  border-radius: 20px;
  /* object-fit: cover; */
}

.speakers .logo_title{
  font-size: 21px;
  color: #313131;
  color: var(--negro);
  font-weight: 600;
}

.speakers .logo_subtitle{
  font-size: 21px;
  color: #313131;
  color: var(--negro);
}



.etm_green{
  color: #95c11f;
  color: var(--verde_oscuro);
  font-weight: bold;
}

.etm_gray{
  color: #707070;
  color: var(--gris_texto);
}

.country-name {
  color: #313131;
  color: var(--negro);
}

.dial-code {
  font-weight: bold;
}



.events .title{
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 35px;
  color: #313131;
  color: var(--negro);
}

.events .cards{
  height: 20rem;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  box-shadow: 3px 3px 3px 3px #D6D6D6;
  box-shadow: 3px 3px 3px 3px var(--sombra);
  transition: box-shadow 0.3s;
}

.events .cards .card_title{
  font-family: "Poppins";
  font-family: var(--fuente);
  font-size: 25px;
  margin: 10px;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

@media (max-width: 767px) {
  .events .cards .card_title{
    font-family: "Poppins";
    font-family: var(--fuente);
    font-size: 20px;
    margin: 10px;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
}

@media (max-width: 576px) {
  .events .cards .card_title{
    font-family: "Poppins";
    font-family: var(--fuente);
    font-size: 25px;
    margin: 10px;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
}

@media (max-width: 327px) {
  .events .cards .card_title{
    font-family: "Poppins";
    font-family: var(--fuente);
    font-size: 20px;
    margin: 10px;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
}

.events .title_2{
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 25px;
  color: #313131;
  color: var(--negro);
}

.events .title_3{
  font-size: 30px;
}

.events .cards_subtitle{
  font-family: "Poppins";
  font-family: var(--fuente);
  font-size: 16px;
  font-weight: bold;
  margin: 10px;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: #313131;
  color: var(--negro);
}

.events .cards_subtitle .head{
  font-size: 25px;
}

.general_only .title{
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 35px;
  color: #313131;
  color: var(--negro);
}

.general_only .cards{
  /* color: var(--negro);
  border-radius: 20px;
  background-repeat: no-repeat;
  background-attachment: scroll;  
  background-position: center center;
  background-size: cover;
  background-color: var(--gris_claro);
  position: relative;
  flex: 1 0 18%; */
}

.postulation .title{
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 35px;
  color: #313131;
  color: var(--negro);
}

.postulation .cards_2{
  color: #313131;
  color: var(--negro);
  border-radius: 20px;
  background-repeat: no-repeat;
  background-attachment: scroll;  
  background-position: center center;
  background-size: cover;
  background-color: #e8e9eb;
  background-color: var(--gris_claro);
  position: relative;
  -webkit-flex: 1 0 18%;
          flex: 1 0 18%;
}

.postulation .cards{
  color: #313131;
  color: var(--negro);
  height: 29rem;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-attachment: scroll;  
  background-position: center center;
  background-size: cover;
  background-color: #e8e9eb;
  background-color: var(--gris_claro);
  position: relative;
  -webkit-flex: 1 0 18%;
          flex: 1 0 18%;
}

.postulation .cards .card_title{
  font-size: 17px;
  margin: 10px;
}

.postulation .cards .card_subtitle{
  font-size: 17px;
  margin: 10px;
}

.postulation .cards .card_btn{
  position: absolute;
  top: 90%;
  left: 27%;
}

.postulation .cards .card_btn_general{
  position: absolute;
  top: 90%;
  left: 27%;
}

@media (max-width: 1400px) {
  .postulation .cards{
    height: 21rem;
    -webkit-flex: 1 0 40%;
            flex: 1 0 40%;
  }
  .postulation .cards .card_btn{
    top: 80%;
    left: 38%;
  }
  .postulation .cards .card_btn_general{
    top: 80%;
    left: 45%;
  }
}

@media (max-width: 1200px) {
  .postulation .cards{
    height: 21rem;
    -webkit-flex: 1 0 40%;
            flex: 1 0 40%;
  }
  .postulation .cards .card_btn{
    top: 85%;
    left: 38%;
  }
  .postulation .cards .card_btn_general{
    top: 80%;
    left: 44%;
  }
}

@media (max-width: 992px) {
  .postulation .cards{
    height: 24rem;
    -webkit-flex: 1 0 40%;
            flex: 1 0 40%;
  }
  .postulation .cards .card_btn{
    top: 85%;
    left: 32%;
  }
  .postulation .cards .card_btn_general{
    top: 85%;
    left: 41%;
  }
}

@media (max-width: 767px) {
  .postulation .cards{
    height: 20rem;
    -webkit-flex: 1 0 100%;
            flex: 1 0 100%;
  }
  .postulation .cards .card_btn{
    top: 85%;
    left: 39%;
  }
  .postulation .cards .card_btn_general{
    top: 85%;
    left: 39%;
  }
}

@media (max-width: 532px) {
  .postulation .cards{
    height: 20rem;
    -webkit-flex: 1 0 100%;
            flex: 1 0 100%;
  }
  .postulation .cards .card_btn{
    top: 85%;
    left: 39%;
  }
  .postulation .cards .card_btn_general{
    top: 85%;
    left: 39%;
  }
}

@media (max-width: 506px) {
  .postulation .cards{
    height: 21rem;
    -webkit-flex: 1 0 100%;
            flex: 1 0 100%;
  }
  .postulation .cards .card_btn{
    top: 85%;
    left: 39%;
  }
  .postulation .cards .card_btn_general{
    top: 85%;
    left: 39%;
  }
}

@media (max-width: 497px) {
  .postulation .cards{
    height: 21rem;
    -webkit-flex: 1 0 100%;
            flex: 1 0 100%;
  }
  .postulation .cards .card_btn{
    top: 85%;
    left: 38%;
  }
  .postulation .cards .card_btn_general{
    top: 85%;
    left: 38%;
  }
}

@media (max-width: 415px) {
  .postulation .cards{
    height: 21rem;
    -webkit-flex: 1 0 100%;
            flex: 1 0 100%;
  }
  .postulation .cards .card_btn{
    top: 85%;
    left: 35%;
  }
  .postulation .cards .card_btn_general{
    top: 85%;
    left: 35%;
  }
}

@media (max-width: 397px) {
  .postulation .cards{
    height: 22rem;
    -webkit-flex: 1 0 100%;
            flex: 1 0 100%;
  }
  .postulation .cards .card_btn{
    top: 85%;
    left: 35%;
  }
  .postulation .cards .card_btn_general{
    top: 85%;
    left: 35%;
  }
}

@media (max-width: 375px) {
  .postulation .cards{
    height: 24rem;
    -webkit-flex: 1 0 100%;
            flex: 1 0 100%;
  }
  .postulation .cards .card_btn{
    top: 87%;
    left: 34%;
  }
  .postulation .cards .card_btn_general{
    top: 87%;
    left: 34%;
  }
}

@media (max-width: 350px) {
  .postulation .cards{
    height: 24rem;
    -webkit-flex: 1 0 100%;
            flex: 1 0 100%;
  }
  .postulation .cards .card_btn{
    top: 87%;
    left: 32%;
  }
  .postulation .cards .card_btn_general{
    top: 87%;
    left: 32%;
  }
}

.postulation .closed {
  width: 100%;
}

@media (max-width: 1400px) {
  .postulation .closed {
    width: 30%;
  }
}

@media (max-width: 1200px) {
  .postulation .closed {
    width: 35%;
  }
}

@media (max-width: 1024px) {
  .postulation .closed {
    width: 38%;
  }
}

@media (max-width: 991px) {
  .postulation .closed {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .postulation .closed {
    width: 80%;
  }
}

@media (max-width: 600px) {
  .postulation .closed {
    width: 70%;
  }
}

@media (max-width: 540px) {
  .postulation .closed {
    width: 28%;
  }
}

@media (max-width: 414px) {
  .postulation .closed {
    width: 40%;
  }
}

.radio-success:after {
  width: 17px;
  height: 17px;
  border-radius: 15px;
  top: -4px;
  left: 0px;
  position: relative;
  background-color: #e8e9eb;
  background-color: var(--gris_claro);
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

.radio-success:checked:after {
  position: relative;
  background-color: #3FBF3F;
  background-color: var(--verde_radio_btn);
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

.gallery .title{
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 35px;
  color: #313131;
  color: var(--negro);
}

.gallery .subtitle{
  font-family: "Poppins";
  font-family: var(--fuente);
  font-size: 25px;
  color: #313131;
  color: var(--negro);
}

.gallery .cards{
  height: 18rem;
  width: auto;
  min-width: 25rem;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  border-radius: 10px;
  box-shadow: 3px 3px 3px 3px #D6D6D6;
  box-shadow: 3px 3px 3px 3px var(--sombra);
  transition: box-shadow 0.3s;
}

@media (max-width: 1400px) {
  .gallery .cards{
    height: 15rem;
    width: auto;
    min-width: 21rem;
  }
}

@media (max-width: 1200px) {
  .gallery .cards{
    height: 13rem;
    width: auto;
    min-width: 18rem;
  }
}

@media (max-width: 992px) {
  .gallery .cards{
    height: 16rem;
    width: auto;
    min-width: 20rem;
  }
}

@media (max-width: 767px) {
  .gallery .cards{
    height: 21rem;
    width: auto;
    min-width: 31rem;
    max-width: 31rem;
  }
}

@media (max-width: 575px) {
  .gallery .cards{
    height: 19rem;
    width: auto;
    min-width: 27rem;
    max-width: 27rem;
  }
}

@media (max-width: 451px) {
  .gallery .cards{
    height: 15rem;
    width: auto;
    min-width: 22rem;
    max-width: 22rem;
  }
}

@media (max-width: 397px) {
  .gallery .cards{
    height: 13rem;
    width: auto;
    min-width: 18rem;
    max-width: 18rem;
  }
}

.pointer{
  cursor: pointer;
}



.partner .title{
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 35px;
  color: #313131;
  color: var(--negro);
}

.partner .diagonal-box {
	background-image: linear-gradient(45deg, #e8e9eb, #e8e9eb);
	background-image: linear-gradient(45deg, var(--gris_claro), var(--gris_claro));
  -webkit-transform: skewY(-3deg);
          transform: skewY(-3deg);
} 
.partner .diagonal-box .content { 	
  margin: 0 auto;
  -webkit-transform: skewY(3deg);
          transform: skewY(3deg);
}

.partner .logo{
  height: auto;
  width: 10rem;
}

.contact .title{
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 35px;
  color: #313131;
  color: var(--negro);
}

.contact label{
  font-family: "Poppins";
  font-family: var(--fuente);
  font-size: 20px;
  font-weight: bold;
  color: #313131;
  color: var(--negro);
}

.contact form{
  margin: 0rem 10rem 0rem 10rem;
}

@media (max-width: 1400px) {
  .contact form{
    margin: 0rem 0rem 0rem 0rem;
  }
}

.etm_input_phone {
  color: #fff !important;
  color: var(--blanco) !important;
  border: none !important;
  border-radius: 0px !important;
  border-bottom: #c1e05f 2px solid !important;
  border-bottom: var(--verde_claro) 2px solid !important;
  background: transparent !important;
  outline: none !important;
  width: 100% !important;
  font-size: 16px !important;
}

.etm_input_phone_flag {
  color: #fff !important;
  color: var(--blanco) !important;
  border: none !important;
  border-radius: 0px !important;
  border-bottom: #c1e05f 2px solid !important;
  border-bottom: var(--verde_claro) 2px solid !important;
  background: transparent !important;
  outline: none !important;
}

.contact .form_etm{
  border: none;
  border-bottom: #c1e05f 2px solid;
  border-bottom: var(--verde_claro) 2px solid;
  border-radius: 0;
}

.contact .form_etm_text{
  border: #c1e05f 2px solid;
  border: var(--verde_claro) 2px solid;
  border-radius: 10px;
}



.btn_etm_1{
  border: none;
  border-radius: 50px;
  padding: 10px 80px 10px 80px;
  background-color: #95c11f;
  background-color: var(--verde_oscuro);
  color: #fff;
  color: var(--blanco);
  font-family: "Poppins";
  font-family: var(--fuente);
  font-weight: bold;
  font-size: 22px;
}

@media (max-width: 1024px){
  .btn_etm_1{
    font-size: 30px;
  }
}

@media (max-width: 768px){
  .btn_etm_1{
    font-size: 22px;
  }
}

@media (max-width: 500px){
  .btn_etm_1{
    font-size: 15px;
    padding: 12px 70px 12px 70px;
  }
}

@media (max-width: 400px){
  .btn_etm_1{
    font-size: 16px;
    padding: 12px 60px 12px 60px;
  }
}

.btn_etm_2{
  border: none;
  padding: 10px 0px 10px 0px;
  background-color: #c1e05f;
  background-color: var(--verde_claro);
  color: #777777;
  color: var(--texto_boton);
  font-family: "Poppins";
  font-family: var(--fuente);
  font-weight: bold;
}

.btn_etm_3{
  border: #777777 1px solid;
  border: var(--texto_boton) 1px solid;
  padding: 10px 50px 10px 50px;
  background-color: #fff;
  background-color: var(--blanco);
  color: #777777;
  color: var(--texto_boton);
  font-family: "Poppins";
  font-family: var(--fuente);
  font-weight: bold;
}

.btn_etm_4{
  border-radius: 20px;
  padding: 6px 30px 6px 30px;
}

.btn_etm_5{
  border: none;
  border-radius: 50px;
  padding: 5px 100px 5px 100px;
  background-color: #95c11f;
  background-color: var(--verde_oscuro);
  color: #fff;
  color: var(--blanco);
  font-family: "Poppins";
  font-family: var(--fuente);
  font-weight: bold;
  font-size: 22px;
}

.text_justify{
  text-align: justify !important;
  text-justify: inter-word !important;
}





#ReactSimpleImageViewer{
  z-index: 9999;
}

.footer {
  margin-top: 60px;
  text-align: center;
  font-size: 0.9rem;
  font-family: "Poppins";
  font-family: var(--fuente);
  background-color: #313131;
  background-color: var(--negro);
}

.footer .rrss_footer {
  height: 30px !important;
  max-height: 30px !important;
  width: auto !important;
  margin-right: 10px !important;
}

.footer .logo_footer {
  height: 50px !important;
  max-height: 50px !important;
  width: auto !important;
}

.modal{
  opacity: 0.92;
}

.modal-content{
  background-color: rgba(49, 49, 49, 1);
  border-radius: 20px;
  color: #fff;
  color: var(--blanco);
  overflow-y: auto !important;
}

.modal-content p{
  font-size: 20px !important;
}

.modal-content .form_etm{
  border: none;
  border-bottom: #95c11f 2px solid;
  border-bottom: var(--verde_oscuro) 2px solid;
  border-radius: 0;
  color: #fff;
  color: var(--blanco);
  background: transparent !important;
  outline: none !important;
  box-shadow: transparent;
}

.modal-content .form_etm:focus { 
  outline: none !important;
  border-color: transparent !important;
  box-shadow: transparent !important;
}

.modal-content .form_etm::-webkit-input-placeholder {
  color: #fff;
  color: var(--blanco);
}

.modal-content .form_etm:-ms-input-placeholder {
  color: #fff;
  color: var(--blanco);
}

.modal-content .form_etm::placeholder {
  color: #fff;
  color: var(--blanco);
}

.modal-content .form_etm_text{
  border: #95c11f 2px solid;
  border: var(--verde_oscuro) 2px solid;
  border-radius: 10px;
  color: #fff;
  color: var(--blanco);
  background: transparent !important;
  outline: none !important;
  box-shadow: transparent;
  font-size: 20px;
}

.modal-content input {
  font-size: 18px;
  font-size: var(--form_size);
}

.modal-content .form_etm_text::-webkit-input-placeholder{
  color: #fff;
  color: var(--blanco);
}

.modal-content .form_etm_text:-ms-input-placeholder{
  color: #fff;
  color: var(--blanco);
}

.modal-content .form_etm_text::placeholder{
  color: #fff;
  color: var(--blanco);
}

.modal-content .form_etm_select{
  border-bottom: #95c11f 2px solid !important;
  border-bottom: var(--verde_oscuro) 2px solid !important;
  color: #313131 !important;
  color: var(--negro) !important;
  background: transparent !important;
  outline: none !important;
  font-size: 18px;
  font-size: var(--form_size);
}

.modal-content .form_etm_select .css-1wa3eu0-placeholder{
  color: #fff !important;
  color: var(--blanco) !important;
}

.modal-content .form_etm_select .css-1yb2wzy-control{
  background: transparent !important;
  outline: none !important;
}
 
.modal-content .form_etm_select .css-9zvo2j-control{
  border: none !important;
  background: transparent !important;
  outline: none !important;
}

.modal-content .form_etm_select .css-1yb2wzy-control{
  border: none !important;
  background: transparent !important;
}

.modal-content .form_etm_select .css-g1d714-ValueContainer{
  color: #fff !important;
  color: var(--blanco) !important;
}

.modal-content .form_etm_select .css-1uccc91-singleValue{
  color: #fff !important;
  color: var(--blanco) !important;
}

.modal-content .btn_etm_1{
    padding: 10px 70px 10px 70px;
}

.events_slider{
  display: none;
}

@media (max-width: 575px) {
  .events_full{
    display: none;
  }
  .events_slider{
    display: block;
  }
}

.gallery_slider{
  display: none;
}

@media (max-width: 767px) {
  .gallery_full{
    display: none;
  }
  .gallery_slider{
    display: block;
  }
}

.slick-arrow{
  background-color: #313131 !important;
  background-color: var(--negro) !important;
  border-radius: 10px;
}

.seccion_video {
  margin-top: -30px;
}

.bg_green {
  background-color: #95c11f;
  background-color: var(--verde_oscuro);
  color: #fff;
  color: var(--blanco);
}

